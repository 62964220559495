<template>
  <div>
    <CreateAssociationEventDetailModal :associationData="associationData" :association_id="association_id"
      :currentIsAdmin="currentIsAdmin" />
    <EditEventDetailModal :association_id="association_id" />
    <div class="event-section">
      <div :class="['sticky-header', { scrolled: isScrolled }]">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <h6>Events</h6>
          <div class="add-new-team-btn position-relative" v-if="currentIsAdmin == 1 || currentIsAdmin == '1'">
            <button type="button" @click="openAddEvent()">
              <img :src="add" alt="add-event" />
              <span>Add Event</span>
            </button>
          </div>
        </div>
        <div class="filter-top">
          <div class="filter-container">
            <div class="filter-cotent">
              <div class="dropdown-wrapper" v-click-outside="closeYearDropdown">
                <button class="dropdown-button" :class="{ 'has-value': filter.startDateFilter }"
                  @click="toggleYearDropdown" :disabled="isLoading">
                  <span>{{ filter.startDateFilter }}</span>
                  <img class="close-down" :src="arrow_down" alt="icon" />
                </button>

                 <div class="dropdown-menu-content" v-show="isYearDropdownOpen && years.length > 0">
                  <div v-for="year in years" :key="year" class="dropdown-item"
                    :class="{ selected: filter.startDateFilter === year }" @click="selectYear(year)">
                    <span>{{ year }}</span>
                  </div>
                </div>
              </div>

              <div class="dropdown-wrapper" v-click-outside="closeEventTypeDropdown">
                <button class="dropdown-button" :class="{ 'has-value': filter.eventType }"
                  @click="toggleEventTypeDropdown" :disabled="isLoading">
                  <span>{{ selectedEventTypeLabel || "Event Type" }}</span>
                  <img class="close-down" :src="arrow_down" alt="icon" />
                </button>

                <div class="dropdown-menu-content" v-show="isEventTypeDropdownOpen">
                  <div v-for="option in filteredEventTypeOptions" :key="option.value" class="dropdown-item"
                    :class="{ selected: filter.eventType === option.value }" @click="selectEventType(option)">
                    <span>{{ option.label }}</span>
                  </div>
                </div>
              </div>

              <button class="filter-button" :class="{ 'filter-button-active': isPastEventsActive }"
                :disabled="isPastEventsDisabled" @click="togglePastEventsFilter()">
                Past Events
              </button>
            </div>
            <button class="reset-button" @click="resetFilters" v-if="isResetVisible">
              Reset Filters
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <div class="event-section-content">

        <div class="event-info-wrapper" v-if="associationEvents.length > 0">
          <!-- <router-link to="/my-event/details" class="mx-0"> -->
          <div class="event-info-sec" v-for="(event, index) in associationEvents" :key="index">
            <div class="event-sec-top" @click="redirectToEvent(event)">
              <div class="sec-date-name">
                <div class="date-time-zone d-flex">
                  <span>{{ formatDateRange(event?.exactStartDate, event?.exactEndDate) }}</span>
                  <span class="ml-1">({{ formatTimeZone(event?.time_zone) }})</span>
                </div>
                <span>{{ event?.eventName }}</span>
              </div>
              <div class="loc-follow w-100">
                <div class="loc-content" v-if="event && event.status === '2' && event.Url">
                  <img :src="link" alt="link" @click.stop="openInNewTab(event.Url)" />
                  <span @click.stop="openInNewTab(event.Url)">{{ event.Url }}</span>
                </div>
                <div class="loc-content" v-else-if="formatLocation(event)">
                  <img :src="location" alt="location" @click.stop="openGoogleMap($event, event)" />
                  <span @click.stop="openGoogleMap($event, event)">{{ formatLocation(event) }}</span>
                </div>
              </div>
            </div>
            <div class="event-sec-details" @click="redirectToEvent(event)">
              <div class="event-sec-left">
                <!-- Conditionally display association and event type -->
                <span class="asso-tour">
                  {{ event.association ? event.association : '' }}
                  {{ event.association && event.eventType ? ' - ' : '' }}
                  {{ event.eventType }}
                </span>
                <span class="director " v-if="event.director_name">Director: {{ event.director_name }}</span>
                <div>
                  <MatesData :members="event.eventJoinedTeam" />
                </div>

              </div>

              <div class="event-sec-right">
                <div class="weather-sec">
                  <WeatherData v-if="isEventWithinFiveDays(event)" :eventId="event.id" />
                </div>
              </div>
            </div>
          </div>
          <!-- </router-link> -->
          <div class="p-3" v-if="showLoader1">
            <content-placeholders :rounded="true">
              <content-placeholders-heading :img="false" />
            </content-placeholders>
          </div>
        </div>

        <div v-if="associationEvents.length" v-observe-visibility="handleScrolledToButtom"></div>

        <div class="no-event-wrap" v-if="(!associationEvents || associationEvents.length === 0) && showLoader === 0">
          <img src="../../assets/Svg/no-data.svg" alt />
          <p class="header">No events found.</p>
          <p class="info">We couldn't find any events. Broaden your search by tweaking the filters above.</p>
        </div>

        <div class="products-list-shim pa-3" v-if="associationEvents.length == 0 && showLoader == 1">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
          <content-placeholders class="mt-3" :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
let states = require("../../assets/js/state");
let eventType = require("../../assets/js/eventType");
import CreateAssociationEventDetailModal from "../Teams/ChatRight/ChatEvents/CreateAssociationEventDetailModal.vue";
import EditEventDetailModal from "../Teams/ChatRight/ChatEvents/EditEventDetailModal.vue";

import MatesData from "./MatesData.vue";
import WeatherData from "@/components/NewGameTime/Events/WeatherData.vue";
import { EventBus } from "@/eventBus";

export default {
  name: "AssociationEventsDetail",
  components: {
    CreateAssociationEventDetailModal,
    EditEventDetailModal,
    MatesData,
    WeatherData
  },
  data() {
    //   const today = new Date();
    // const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    // const currentYear = today.getFullYear();
    return {
      selectedItem: null,
      editEventOptionVisible: false,
      events: {},
      associationData: {},
      open: false,
      showLoader: 1,
      association_id: 0,
      showLoader1: 0,
      loading: true,
      fields: [
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = new Date(item.exactStartDate);
            const endDate = new Date(item.exactEndDate);
            const startDateString = startDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const endDateString = endDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const startYearString = startDate.getFullYear();
            const endYearString = endDate.getFullYear();

            if (startYearString === endYearString) {
              return `${startDateString} to ${endDateString}, ${startYearString}`;
            } else {
              return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
            }
          },
        },
        // { key: "association", label: "Association", sortable: true },
        { key: "eventName", label: "Name", sortable: true },
        { key: "director_name", label: "Director", sortable: true },
        { key: "eventType", label: "Type", sortable: true },
        {
          key: "location",
          label: "Location",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return `${item.address || ""}, ${item.city || ""}, ${item.state || ""}`;
          },
          sortBy: (item) => {
            const location =
              (item.address || "") + " " + (item.city || "") + " " + (item.state || "");
            return location.toLowerCase();
          },
        },
        { key: "state", label: "State", sortable: true },
        { key: "action", label: "Action", class: "text-right" },
      ],
      isLoading: false,
      associationEvents: [],
      currentIsAdmin: 0,
      page: 1,
      lastPage: 1,
      total: 0,
      filter: {
        eventType: "",
        associationFilter: "",
        stateFilter: "",
        eventStatus: false,
        startDateFilter: new Date().getFullYear(),
      },
      stateOptions: states,
      associationOptions: [],
      eventTypeOptions: eventType,
      filterApplied: false,
      associationGuid: "",
      invalidEventAssociation: false,
      isEventTypeDropdownOpen: false,
      isYearDropdownOpen: false,
      arrow_down: require("../../assets/Svg/arrow-down.svg"),
      years: [],
      isPastEventsActive: false, // Track active state for Past Events
      isPastEventsDisabled: false, // Track active state for Past Events
      isScrolled: false,
      add: require("../../assets/Svg/add.svg"),
      location: require("../../assets/Svg/location.svg"),
      link: require("../../assets/images/link.png"),
    };
  },

  computed: {
    filteredEventTypeOptions() {
      return this.eventTypeOptions.filter(option => option.status === 1);
    },
    isResetVisible() {
      return (
        this.filter.eventType ||
        this.isPastEventsActive
      );
    },
    selectedEventTypeLabel() {
      const selected = this.eventTypeOptions.find(
        (option) => option.value === this.filter.eventType
      );
      return selected ? selected.label : null;
    },
    sortedEvents() {
      const events = this.associationEvents.slice();
      if (this.sortKey === "exactStartDate") {
        events.sort((a, b) => {
          const dateA = new Date(a[this.sortKey]);
          const dateB = new Date(b[this.sortKey]);

          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0; // handle invalid dates
          }

          return this.reverse
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
      } else if (this.sortKey === "location") {
        events.sort((a, b) => {
          const locationA =
            (a.address || "") + " " + (a.city || "") + " " + (a.state || "");

          const locationB =
            (b.address || "") + " " + (b.city || "") + " " + (b.state || "");

          return this.reverse
            ? locationB.localeCompare(locationA)
            : locationA.localeCompare(locationB);
        });
      } else if (this.sortKey) {
        events.sort((a, b) => {
          const fieldA = a[this.sortKey];
          const fieldB = b[this.sortKey];

          if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else {
            return 0;
          }
        });
      }
      return events;
    },
  },

  methods: {
    redirectToEvent(item) {
      const eventId = item.guid;
      if (this.currentIsAdmin) {
        // Handle row click before navigating
        this.handleRowClick(item);
      }

      const routeName = this.currentIsAdmin ? 'AssociationSingleEventDetail' : 'EventGame';

      this.$router.push({ name: routeName, params: { id: eventId } });
    },
    openAddEvent() {
      EventBus.$emit("open-association");
    },
    toggleYearDropdown() {
      this.isYearDropdownOpen = !this.isYearDropdownOpen;
    },
    closeYearDropdown() {
      this.isYearDropdownOpen = false;
    },
    selectYear(year) {
      this.filter.startDateFilter = year;

      const currentYear = new Date().getFullYear();

      if (year < currentYear) {
        this.isPastEventsActive = true;
        this.isPastEventsDisabled = true; // Disable the button when a past year is selected
      } else {
        this.isPastEventsActive = false;
        this.isPastEventsDisabled = false; // Enable the button for the current or future year
      }
      this.fetchEvents(); // Trigger API call
      this.closeYearDropdown();
    },
    formatDateRange(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const startDateString = start.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      const endDateString = end.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      const startYear = start.getFullYear();
      const endYear = end.getFullYear();

      if (startYear === endYear) {
        return `${startDateString} to ${endDateString}, ${startYear}`;
      } else {
        return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
      }
    },
    formatTimeZone(timeZone) {
      if (timeZone && typeof timeZone === 'string') {
        return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
      }
      return timeZone; // Return the original value if it's not a valid string
    },
    isEventWithinFiveDays(event) {
        if (!event?.startDate || !event?.endDate) return false;

        if (event && event.status === '2' && event.Url) {
            console.log("Skipping weather data fetch for online event.");
            return;
          }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Calculate differences in days
        const daysFromTodayToStart = (startDate - today) / (1000 * 60 * 60 * 24);
        const daysFromTodayToEnd = (endDate - today) / (1000 * 60 * 60 * 24);

        // Check if event starts or ends within 5 days
        const startsWithinFiveDays = daysFromTodayToStart >= 0 && daysFromTodayToStart <= 5;
        const endsWithinFiveDays = daysFromTodayToEnd >= 0 && daysFromTodayToEnd <= 5;
        const ongoingToday = today >= startDate && today <= endDate;

        // **EXTRA CONDITION**: If event duration is too long, exclude it
        const eventDuration = (endDate - startDate) / (1000 * 60 * 60 * 24);
        if (eventDuration > 10) return false; // Example: Ignore events longer than 10 days

        return startsWithinFiveDays || endsWithinFiveDays || ongoingToday;
      },
    formatLocation(event) {
      let location = '';

      if (event.city) {
        // Add comma if address exists, else just city
        location += (location ? ', ' : '') + event.city;
      }

      if (event.state) {
        // Add comma if address or city exists, else just state
        location += (location ? ', ' : '') + event.state;
      }

      return location;
    },
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },
    toggleEventTypeDropdown() {
      this.isEventTypeDropdownOpen = !this.isEventTypeDropdownOpen;
    },
    closeEventTypeDropdown() {
      this.isEventTypeDropdownOpen = false;
    },
    selectEventType(option) {
      this.filter.eventType = option.value;
      this.fetchEvents();
      this.closeEventTypeDropdown();
    },

    resetFilters() {
      this.filter.eventType = "";
      this.filter.stateFilter = "";
      this.filter.eventStatus = false;
      this.filter.associationFilter = "";
      this.filter.startDateFilter = new Date().getFullYear();
      this.isPastEventsActive = false;
      this.isPastEventsDisabled = false;
      this.fetchEvents();
    },
    togglePastEventsFilter() {
      if (this.isPastEventsDisabled) return;
      this.teamEventInfo = [];
      this.isPastEventsActive = !this.isPastEventsActive;
      this.filter.eventStatus = !this.filter.eventStatus;
      this.page = 1;
      this.fetchEvents(); // Always call calendarData() method to fetch data
    },
    toggleEditEventOption(item) {
      this.selectedItem = item;
      this.editEventOptionVisible = !this.editEventOptionVisible;
    },
    onClickOutside() {
      this.editEventOption = false;
    },
    editEvent(item) {
      this.$root.$emit("updateEditEventDetailModal", item);
    },
    handleRowClick(event) {
      this.$root.$emit("eventsIdForDetails", event);
      // this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
    },

    clearEventType() {
      this.filter.eventType = "";
      this.page = 1;
      this.filterApplied = false;
      this.associationEvents = [];
      this.fetchEvents();
    },
    clearState() {
      this.filter.stateFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.associationEvents = [];
      this.fetchEvents();
    },
    checkDateInput() {
      if (!this.filter.startDateFilter) {
        this.filter.startDateFilter = "";
        this.filterApplied = false;
      }
      this.page = 1;
      this.associationEvents = [];
      this.fetchEvents();
    },
    checkEventType() {
      this.associationEvents = [];
      this.page = 1;
      this.fetchEvents();
    },
    handleClick(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.fetchEvents2();
      }
    },

    async fetchEvents() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      this.page = 1;
      this.associationEvents = [];

      if (!this.associationGuid) {
        const url = window.location.pathname;
        const segment_array = url.split("/");
        this.associationGuid = segment_array.pop();
      }

      // let formattedStartDate = "";
      // if (this.filter.startDateFilter) {
      //   const startDateParts = this.filter.startDateFilter.split("/");
      //   formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`;
      // }

      const formData = new FormData();
      formData.append("associationGuid", this.associationGuid);
      formData.append("event_type", this.filter.eventType);
      const currentYear = new Date().getFullYear();
      const selectedYear = this.filter.startDateFilter;
      const eventStatus = selectedYear < currentYear ? "true" : this.filter.eventStatus;

      formData.append("selectedYear", selectedYear);
      formData.append("event_status", eventStatus);
      if (this.filter.stateFilter) {
        formData.append("region", this.filter.stateFilter.name);
        formData.append("abbreviation", this.filter.stateFilter.abbreviation);
      }

      // if ((this.filter.eventType || this.filter.startDateFilter || this.filter.stateFilter) &&
      //   !this.filterApplied) {
      //   this.page = 1;
      //   this.associationEvents = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/fetchByAssociation?page=${this.page}`,
          formData
        );

        if (response.data.statusCode === 200) {
          this.associationEvents = response.data.events.data;
          // Check if newAssociationData is defined and not empty
          if (this.associationEvents && this.associationEvents.length > 0) {
            // if (this.page === 1) {
            //   this.associationEvents = [];
            // }

            // this.associationEvents = this.associationEvents.concat(newAssociationData);
            this.associationEvents.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);

              if (dateAStart.getTime() !== dateBStart.getTime()) {
                return dateAStart - dateBStart;
              } else {
                return dateAEnd - dateBEnd;
              }
            });
          } else {
            console.warn("No events data found");
          }

          this.showLoader = 0;
          this.association_id = response.data.association.id;
          this.currentIsAdmin = response.data.currentIsAdmin;
          this.associationData = response.data.association;
          this.years = response.data.eventYears
          EventBus.$emit("associationData", this.associationData);
          this.$root.$emit("associationTopData", this.associationData, this.showLoader, this.currentIsAdmin);

          this.lastPage = response.data.events.last_page;
        } else if (response.data.statusCode === 404) {
          this.showLoader = 0;
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else if (response.data.statusCode === 400) {
          this.invalidEventAssociation = true;
          this.showLoader = 0;
          this.$root.$emit("invalidAssociationEvents", this.invalidEventAssociation, this.showLoader);
        } else {
          console.error("Error:", response.data.message || "Unknown error occurred");
          this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },

    async fetchEvents2() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;

      if (!this.associationGuid) {
        const url = window.location.pathname;
        const segment_array = url.split("/");
        this.associationGuid = segment_array.pop();
      }

      // let formattedStartDate = "";
      // if (this.filter.startDateFilter) {
      //   const startDateParts = this.filter.startDateFilter.split("/");
      //   formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`;
      // }

      const formData = new FormData();
      formData.append("associationGuid", this.associationGuid);
      formData.append("event_type", this.filter.eventType);
      const currentYear = new Date().getFullYear();
      const selectedYear = this.filter.startDateFilter;
      const eventStatus = selectedYear < currentYear ? "true" : this.filter.eventStatus;

      formData.append("selectedYear", selectedYear);
      formData.append("event_status", eventStatus);


      if (this.filter.stateFilter) {
        formData.append("region", this.filter.stateFilter.name);
        formData.append("abbreviation", this.filter.stateFilter.abbreviation);
      }

      // if ((this.filter.eventType || this.filter.startDateFilter || this.filter.stateFilter) &&
      //   !this.filterApplied) {
      //   this.page = 1;
      //   this.associationEvents = [];
      //   this.filterApplied = true;
      // }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/fetchByAssociation?page=${this.page}`,
          formData
        );

        if (response.data.statusCode === 200) {
          const newAssociationData = response.data.events.data;
          // Check if newAssociationData is defined and not empty
          if (newAssociationData && newAssociationData.length > 0) {
            if (this.page === 1) {
              this.associationEvents = [];
            }

            this.associationEvents = this.associationEvents.concat(newAssociationData);
            // this.associationEvents.sort((a, b) => {
            //   const dateAStart = new Date(a.exactStartDate);
            //   const dateBStart = new Date(b.exactStartDate);
            //   const dateAEnd = new Date(a.exactEndDate);
            //   const dateBEnd = new Date(b.exactEndDate);

            //   if (dateAStart.getTime() !== dateBStart.getTime()) {
            //     return dateAStart - dateBStart;
            //   } else {
            //     return dateAEnd - dateBEnd;
            //   }
            // });
          } else {
            console.warn("No events data found");
          }

          this.showLoader = 0;
          this.association_id = response.data.association.id;
          // this.currentIsAdmin = response.data.currentIsAdmin;
          this.associationData = response.data.association;
          this.years = response.data.eventYears
          EventBus.$emit("associationData", this.associationData);
          this.$root.$emit("associationTopData", this.associationData, this.showLoader, this.currentIsAdmin);

          this.lastPage = response.data.events.last_page;
        } else if (response.data.statusCode === 404) {
          this.showLoader = 0;
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else if (response.data.statusCode === 400) {
          this.invalidEventAssociation = true;
          this.showLoader = 0;
          this.$root.$emit("invalidAssociationEvents", this.invalidEventAssociation, this.showLoader);
        } else {
          console.error("Error:", response.data.message || "Unknown error occurred");
          this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchEvents();
    this.$root.$on("fetchAssociationEvents", () => {
      this.associationEvents = [];
      this.page = 1;
      this.fetchEvents();
    });
    this.$root.$on("associationRoute", (data) => {
      this.associationGuid = data.guid;
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;
      this.associationEvents = [];
      this.associationData = {},
        this.resetFilters();
    });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>
<style scoped>
.table thead th {
  min-width: 150px;
}

.eye-icon {
  width: 30px;
}

.no-game-section {
  height: 150px !important;
}

.no-products-in-shop {
  height: auto !important;
}

.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}

.form-group select {
  border: 1px solid #ced4da !important;
}

.clear-icon {
  position: absolute;
  top: 44%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
  font-size: 14px;
}

.event-cancel {
  background-color: #ff5b66;
  color: white;
  border-radius: 50px;
  padding: 0px;
}
</style>
