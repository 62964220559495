<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-md-0">
        <LeftMenuSecondaryAssociation :openTabSet="openTabSet" :currentIsAdmin="currentIsAdmin" />

        <div class="single-event-page bg-white" v-if="invalidEventsData">
          <div class="card-event col-12 no-card-event">
            <div class="img">
              <img src="../assets/images/games.png" alt="" />
            </div>
            <p class="text">No events association found.</p>
          </div>
        </div>

        <div class="row no-gutters content-wrap-info" v-else>
          <div class="event-section-wrap team-section-wrap">
            <!-- <AssociationDetail
              :associationData="associationData"
              :showLoader="showLoader"
            />
            <AssociationTab
              :associationData="associationData"
              :currentIsAdmin="currentIsAdmin"
            /> -->
            <AssociationEventsDetail />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
// import AssociationDetail from "../components/Association/associationDetail.vue";
// import AssociationTab from "../components/Association/associationTab.vue";
import AssociationEventsDetail from "../components/Association/associationEventsDetail.vue";
import LeftMenuSecondaryAssociation from "../components/Association/LeftMenuSecondaryAssociation.vue";

export default {
  name: "AssociationEvents",
  data() {
    return {
      associationData: {},
      showLoader: 1,
      currentIsAdmin: 0,
      invalidEventsData: false,
      openTabSet: 1,
    };
  },
  components: {
    Navbar,
    LeftMenu,
    Banner,
    // AssociationDetail,
    // AssociationTab,
    AssociationEventsDetail,
    LeftMenuSecondaryAssociation
  },
  methods: {},
  mounted() {
    this.$root.$on(
      "associationTopData",
      (associationData, showLoader, currentIsAdmin) => {
        this.associationData = associationData;
        this.showLoader = showLoader;
        this.currentIsAdmin = currentIsAdmin;
      }
    );
    this.$root.$on(
      "invalidAssociationEvents",
      (invalidEventAssociation, showLoader) => {
        this.invalidEventsData = invalidEventAssociation;
        this.showLoader = showLoader;
      }
    );
    this.$root.$on("openTabSetting", (id) => {
      this.openTabSet = id;
    });
  },
};
</script>